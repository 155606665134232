import React from "react";
import Layout from "../../components/layout";
import Image from "../../components/elements/Image";
import {
  ImageAngle,
  ModelsHeader,
  Text,
  CardTitle,
  CardLine,
  CardText,
  Card, CardImg, CardContent, CardList
} from "../../components/models/models.styles";
import { TopCard, BlockRow, BlockRowContent, BlockRowImage, BlackGrid, RelativeImage, RelativeLabel, Card5 } from "../../components/models/durango.styles"
import { Title } from "../../styles/typography.styles";
import { Row } from "../../components/home/home.styles";
import * as DodgeJson from '../../data/dodge.json';

const Challenger = () => {
  const durango  = DodgeJson.default.filter(item => item.model === "durango");

  return (
    <Layout title={'Dodge Durango'}>
      <ModelsHeader>
        <Image fileName={'durango_banner@2x.jpg'} alt={"Dodge Durango"}/>
      </ModelsHeader>

      <main>
        <div className={`container`}>
          <Title>DODGE CHALLENGER TRIM</Title>

          <Row>
            <TopCard>
              <CardTitle>DODGE DURANGO R/T</CardTitle>
              <CardLine/>
              <Image fileName={"Durango-RT_600x350@2x.jpg"} alt={"DODGE DURANGO R/T"}/>
              <CardList>
                <li>Silnik HEMI® V8 5,7 l / 360 KM</li>
                <li>Wydajne sterowanie i sportowe zawieszenie</li>
                <li>Uconnect® 4C NAV z 8,4-calowym ekranem dotykowym i systemem dźwiękowym BeatsAudio ™ Premium</li>
                <li>7 miejsc (dostępne również 6 miejsc)</li>
                <li>Napęd na wszystkie koła</li>
              </CardList>
            </TopCard>
            <TopCard>
              <CardTitle>DODGE DURANGO SRT</CardTitle>
              <CardLine/>
              <Image fileName={"DURANGO-SRT_600x350@2x.jpg"} alt={"DODGE DURANGO SRT"}/>
              <CardList>
                <li>Silnik HEMI® SRT V8 o pojemności 6,4 l / 475 KM</li>
                <li>0-100 km/h w 4,4 s</li>
                <li>prędkość maksymalna: 290 km / h</li>
                <li>Sześciotłokowe hamulce Brembo</li>
                <li>zawieszenie Bilstein® o wysokiej wydajności</li>
                <li>20 głośników Harman Kardon ™</li>
                <li>6 krzeseł kapitańskich</li>
                <li>skórzana tapicerka w kolorze Demonic red laguna</li>
                <li>System rozrywki DVD z tyłu</li>
              </CardList>
            </TopCard>
            <TopCard>
              <CardTitle>DODGE DURANGO SRT HELLCAT</CardTitle>
              <CardLine/>
              <Image fileName={"Durango-hellcat_600x350@2x.jpg"} alt={"DODGE DURANGO SRT HELLCAT"}/>
              <CardList>
                <li>Silnik SRT HELLCAT V8 o pojemności 6,2 l / 710 KM</li>
                <li>Ośmiobiegowa automatyczna skrzynia biegowa</li>
                <li>Nowy system zawieszenia SRT</li>
                <li>Nowy tylny spojler</li>
                <li>Nowe wspomaganie kierownicy</li>
              </CardList>
            </TopCard>
          </Row>
        </div>

        <div className="container">
          <Title>ODKRYJ SWOJEGO DURANGO</Title>

          <ImageAngle>
            <Image fileName={"durango_01@2x.png"} alt={"Dodge Durango"}/>
          </ImageAngle>

          <BlockRow>
            <BlockRowContent>
              <CardTitle>SZCZYT <br/>wydajności</CardTitle>
              <CardLine/>
              <CardText>
                Fantastyczna moc Durango SRT® pozostawia konkurentów w tyle. Dzięki trybom jazdy SRT® możesz odblokować prawdziwie potężny pojazd o mocy do 475 koni mechanicznych.
              </CardText>
            </BlockRowContent>

            <BlockRowImage>
              <Image fileName={"durango_02@2x.png"} alt={"Dodge Durango"}/>
            </BlockRowImage>
          </BlockRow>
        </div>

        <BlackGrid>
          <Title color="white">z zewnątrz</Title>

          <ImageAngle className={`container`}>
            <Image fileName={"durango_exterior_01@2x.png"} alt={"Dodge Durango"}/>
          </ImageAngle>

          <BlockRow className={`container down`}>
            <BlockRowImage>
              <Image fileName={"durango_exterior_02@2x.png"} alt={"Dodge Durango"}/>
            </BlockRowImage>

            <BlockRowContent>
              <CardTitle color={"white"}>ZRÓB <br/>WRAŻENIE</CardTitle>
              <CardLine/>
              <CardText className={'text'}>
                Wykreuj swój styl dzięki 11 dostępnym kolorom nadwozia, drapieżnie wyglądającej desce rozdzielczej, reflektorom LED oraz charakterystycznym tylnym lampom przeciwmgielnym.
              </CardText>
            </BlockRowContent>
          </BlockRow>
        </BlackGrid>

        <div className="container">
          <Title>Oświetli ci drogę</Title>

          <RelativeImage className={`container`}>
            <Image fileName={"durango_exterior_03@2x.png"} alt={"Dodge Durango"}/>

            <RelativeLabel style={{ top: "-4rem", left: "1.5rem" }}>
              <h5 className="title">NIC NIE UMKNIE <br/> TWOJEJ UWADZE</h5>
              <CardLine />
              <div className="text">
                Automatyczne reflektory świateł drogowych włączają się we właściwym momencie, zapewniając maksymalną widoczność w każdym otoczeniu. Eleganckie światła LED do jazdy dziennej zapewniają, unikatowy wygląd, który sprawia, że wyróżniasz się na drodze.
              </div>
            </RelativeLabel>

            <RelativeLabel style={{ bottom: "-4rem", right: "1.5rem" }}>
              <h5 className="title align-right">NIC NIE UMKNIE <br/> TWOJEJ UWADZE</h5>
              <CardLine />
              <div className="text align-right">
                Wyjątkowa w tej klasie tylna lampa LED jest symbolem „muscle-car” marki Dodge i pokazuje, że jesteś siłą, której nie można lekceważyć.
              </div>
            </RelativeLabel>
          </RelativeImage>
        </div>

        <div className="container">
          <Title>Wnętrze</Title>

          <Image fileName={"durango_interior_01@2x.jpg"} alt={"Dodge Durango"}/>

          <BlockRow>
            <BlockRowContent>
              <CardTitle>POCZUJ <br/>SWOBODĘ</CardTitle>
              <CardLine/>
              <CardText>
                Dzięki ponad 50 konfiguracjom siedzeń i maksymalnej przestrzeni ładunkowej wynoszącej prawie 2,5 metra sześciennego, Durango zapewnia komfortową jazdę.
              </CardText>
            </BlockRowContent>

            <BlockRowImage>
              <Image fileName={"durango_interior_02@2x.png"} alt={"Dodge Durango"}/>
            </BlockRowImage>
          </BlockRow>

          <Image fileName={"durango_interior_03@2x.png"} alt={"Dodge Durango"}/>
        </div>

        <BlackGrid>
          <Title color="white">ponad 50 konfiguracji siedzeń</Title>

          <ImageAngle className={`container`}>
            <Image fileName={"durango_interior_04@2x.png"} alt={"Dodge Durango"}/>
          </ImageAngle>
        </BlackGrid>

        <div className="container">
          <Title>MAKSYMALNA PRZESTRZEŃ ŁADUNKOWA</Title>

          <Text>
            Więcej miejsca, więcej pojemności, <br/> więcej do zabrania
          </Text>

          <BlockRow>
            <TopCard>
              <Image fileName={"durango_interior_room_01@2x.png"} alt={"Dodge Durango"}/>
              <CardTitle>PRZESTRZEŃ <br/> DLA SIEDMIU OSÓB</CardTitle>
              <CardLine/>
              <CardText>
                Dzięki trzem rzędom i maksymalnie siedmiu siedzeniom, Ty i twoja załoga możecie wygodnie i pewnie dopasować się do każdej ekscytującej podróży (Durango SRT max. sześć miejsc)
              </CardText>
            </TopCard>
            <TopCard>
              <Image fileName={"durango_interior_room_02@2x.png"} alt={"Dodge Durango"}/>
              <CardTitle>DODATKOWE <br/> MIEJSCE</CardTitle>
              <CardLine/>
              <CardText>
                Kanapy w trzecim rzędzie, dzielone w proporcji 60/40, można złożyć, aby uzyskać więcej miejsca na bagaż.
              </CardText>
            </TopCard>
            <TopCard>
              <Image fileName={"durango_interior_room_03@2x.png"} alt={"Dodge Durango"}/>
              <CardTitle>SKŁADANIE SIEDZENIA <br/> W DRUGIM RZęDZIE</CardTitle>
              <CardLine/>
              <CardText>
                Łatwe wchodzenie i wychodzenie w dowolnym momencie
              </CardText>
            </TopCard>
          </BlockRow>
        </div>

        <ImageAngle className={`container`}>
          <Image fileName={"durango_interior_seating_00@2x.png"} alt={"Dodge Durango"}/>
        </ImageAngle>

        <div className="container">
          <BlockRow>
            <TopCard>
              <Image fileName={"durango_interior_seating_01@2x.png"} alt={"Dodge Durango"}/>
              <CardTitle>LUKSUSOWE <br/> WNĘTRZE</CardTitle>
              <CardLine/>
              <CardText>
                Dostępne siedzenia pokryte skórą Nappa pozostają miękkie, gładkie i luksusowe przez wiele lat.
              </CardText>
            </TopCard>
            <TopCard>
              <Image fileName={"durango_interior_seating_02@2x.png"} alt={"Dodge Durango"}/>
              <CardTitle>KOMFORT <br/> JEST KLUCZOWY</CardTitle>
              <CardLine/>
              <CardText>
                Durango został stworzony z myślą o komforcie. Jednym z jego elementów są podgrzewane i wentylowane przednie fotele z ośmiokierunkową elektryczną regulacją i czterostronnym podparciem lędźwi.
              </CardText>
            </TopCard>
            <TopCard>
              <Image fileName={"durango_interior_seating_03@2x.png"} alt={"Dodge Durango"}/>
              <CardTitle>POZYTYWNIE <br/> NAŁADOWANY</CardTitle>
              <CardLine/>
              <CardText>
                Pasażerowie w drugim rzędzie również zasługują na wygodę, a konsola środkowa zapewnia wszystko, czego potrzebują. Port USB, gniazdo 115 V oraz klimatyzację.
              </CardText>
            </TopCard>
          </BlockRow>
        </div>

        <BlackGrid>
          <Title color="white">Technologia</Title>

          <BlockRow className={`container`}>
            <BlockRowImage>
              <Image fileName={"durango_tech_00@2x.png"} alt={"Dodge Durango"}/>
            </BlockRowImage>

            <BlockRowContent>
              <CardTitle color={"white"}>PODWÓJNY EKRAN <br/>BLU-RAY ™</CardTitle>
              <CardLine/>
              <CardText className={'text'}>
                Dostępny 9-calowy system multimedialny Blu-ray ™ o wysokiej rozdzielczości zapewnia rozrywkę pasażerom bez względu na długość podróży.
              </CardText>
            </BlockRowContent>
          </BlockRow>
        </BlackGrid>

        <BlockRow className={`container`} style={{marginTop: "-6rem"}}>
          <TopCard>
            <Image fileName={"durango_tech_01@2x.png"} alt={"Dodge Durango"}/>
            <CardTitle>wyświetlacz multimedialny <br/> kierowcy</CardTitle>
            <CardLine/>
            <CardText>
              Miej wszystkie informacje na wyciągnięcie ręki dzięki 7-calowemu informacyjnemu wyświetlaczowi.
            </CardText>
          </TopCard>
          <TopCard>
            <Image fileName={"durango_tech_02@2x.png"} alt={"Dodge Durango"}/>
            <CardTitle>POLECENIE GŁOSOWE <br/> UCONNECT®</CardTitle>
            <CardLine/>
            <CardText>
              Trzymaj ręce na kierownicy podczas rozmów w trybie głośnomówiącym i dostępu do systemu Uconnect®. Wszystko to obsłużysz za pomocą komend głosowych.
            </CardText>
          </TopCard>
          <TopCard>
            <Image fileName={"durango_tech_03@2x.png"} alt={"Dodge Durango"}/>
            <CardTitle>MANETKI <br/> ZMIANY BIEGÓW</CardTitle>
            <CardLine/>
            <CardText>
              Kiedy chcesz przejąć pełną kontrolę, zrób to z wygodą i pewnością.
            </CardText>
          </TopCard>
        </BlockRow>

        <div className="container">
          <Title>UCONNECT®</Title>

          <Text>
            Technologia, która pomaga zapewnić <br/> bezproblemową jazdę.
          </Text>

          <ImageAngle className={`container m0`}>
            <Image fileName={"durango_uconnect@2x.png"} alt={"Dodge Durango"}/>
          </ImageAngle>

          <BlockRow>
            <TopCard>
              <CardTitle>ANDROID <br/> AUTO ™</CardTitle>
              <CardLine/>
              <CardText>
                Uzyskaj dostęp do funkcji swojego smartfona z systemem Android za pośrednictwem ekranu dotykowego Uconnect®.
              </CardText>
            </TopCard>
            <TopCard>
              <CardTitle>APPLE <br/> CARPLAY®</CardTitle>
              <CardLine/>
              <CardText>
                Korzystaj w trakcie jazdy z wybranych aplikacji na iPhone'a® w inteligentny i bezpieczny sposób.
              </CardText>
            </TopCard>
            <TopCard>
              <CardTitle>DOSTĘPNA <br/> NAWIGACJA</CardTitle>
              <CardLine/>
              <CardText>
                Śledź trasę i otrzymuj aktualizacje zawierające wszystkie informacje potrzebne do bezpiecznego i punktualnego dotarcia na miejsce. Nawigacja zawiera mapy europejskie.
              </CardText>
            </TopCard>
          </BlockRow>
        </div>

        <div className="container">
          <Title>Osiągi</Title>

          <Text>
            Od silnika po zawieszenie - przejmij kontrolę nad zachowaniem samochodu <br/>
            w dowolnym momencie.
          </Text>

          <ImageAngle className={`container m0`}>
            <Image fileName={"durango_performance@2x.png"} alt={"Dodge Durango"}/>
          </ImageAngle>
        </div>

        <div className="container">
          <Title>Otocz się dźwiękiem</Title>

          <Text className={`mb40`}>
            Każda podróż wymaga piosenki przewodniej. <br/>
            Wybierz swoją i delektuj się jazdą.
          </Text>

          <RelativeImage className={`container`}>
            <Image fileName={"sound@2x.png"}/>

            <RelativeLabel style={{ bottom: "-8rem", left: "1.5rem" }}>
              <h5 className="title">HARMAN KARDON® <br/> AUDIO GROUP</h5>
              <CardLine />
              <div className="text">
                Pozwól się przenieść w nowy muzyczny wymiar dzięki 900 watom, dwóm subwooferom oraz zestawowi 18 głośników.
              </div>
            </RelativeLabel>
          </RelativeImage>
        </div>

        <BlackGrid>
          <Title color="white">OCHRONA I BEZPIECZEŃSTWO</Title>

          <BlockRow className={`container`}>
            <BlockRowContent>
              <CardTitle color={"white"}>Stworzony <br/>by chronić</CardTitle>
              <CardLine/>
              <CardText className={'text'}>
                Dodge Durango to siła stworzona by chronić Cię przed całym światem. Posiada mocną i bezpieczną stalową ramę, która pomaga zapewnić bezpieczeństwo w razie wypadku.
              </CardText>
            </BlockRowContent>

            <BlockRowImage>
              <Image fileName={"durango_safety@2x.jpg"} alt={"Dodge Durango"}/>
            </BlockRowImage>
          </BlockRow>
        </BlackGrid>

        <div className="container">
          <Title>Twój opiekun</Title>

          <ImageAngle className={`container m0`}>
            <Image fileName={"durango_safety_01@2x.png"} alt={"Dodge Durango"}/>
          </ImageAngle>

          <BlockRow>
            <Card5>
              <CardTitle>AUTOMATYCZNE ŚWIATŁA <br/> DROGOWE </CardTitle>
              <CardLine/>
              <CardText>
                Światła drogowe przełączają się automatycznie na światła mijania, gdy wykryją z naprzeciwka inne źródło oświetlenia.
              </CardText>
            </Card5>
            <Card5>
              <CardTitle>MONITOROWANIE <br/> OTOCZENIA </CardTitle>
              <CardLine/>
              <CardText>
                System monitorowania martwego pola pomaga w zmianie pasa ruchu, ostrzegając, gdy inny pojazd wjeżdża do jednej z twoich stref.
              </CardText>
            </Card5>
            <Card5>
              <CardTitle>ADAPTACYJNY <br/> TEMPOMAT </CardTitle>
              <CardLine/>
              <CardText>
                System automatycznie dostosowuje prędkość, aby pomóc utrzymać określoną odległość od poprzedzającego pojazdu.
              </CardText>
            </Card5>
            <Card5>
              <CardTitle>TYLNA KAMERA <br/> PARKVIEW® </CardTitle>
              <CardLine/>
              <CardText>
                Tylna kamera cofania ParkView® wyświetla to co dzieje się za Tobą na ekranie dotykowym Uconnect®, gdy zmieniasz bieg na wsteczny. Linie siatki pomagają w manewrowaniu na parkingach lub w wąskich obszarach.
              </CardText>
            </Card5>
            <Card5>
              <CardTitle>OSTRZEŻENIE PRZED KOLIZJĄ</CardTitle>
              <CardLine/>
              <CardText>
                System ostrzegania przed kolizją przesyła sygnał dźwiękowy oraz wizualny. W razie potrzeby zacznie zwalniać pojazd.
              </CardText>
            </Card5>
          </BlockRow>
        </div>

        <div className="container">
          <Title>BĄDŹ PRZYGOTOWANY®</Title>

          <Text style={{maxWidth: "80rem"}}>
            Czasami coś dzieje się bez ostrzeżenia. Dodge Durango jest zaprojektowany z największą starannością, aby zapewnić tobie i twoim pasażerom bezpieczeństwo w każdej sytuacji
          </Text>

          <ImageAngle className={`container m0`}>
            <Image fileName={"durango_safety_02@2x.png"} alt={"Dodge Durango"}/>
          </ImageAngle>

          <BlockRow>
            <TopCard>
              <CardTitle>STREFY <br/> ZGNIOTU</CardTitle>
              <CardLine/>
              <CardText>
                Specjalnie zaprojektowane strefy zgniotu z przodu i z tyłu pomagają absorbować energię uderzenia i przekierowywać ją z dala od pasażerów, aby zmniejszyć ryzyko obrażeń.
              </CardText>
            </TopCard>
            <TopCard>
              <CardTitle>ELEKTRONICZNY ROZKŁAD <br/> SIŁY HAMOWANIA</CardTitle>
              <CardLine/>
              <CardText>
                Niezależnie od warunków obciążenia pojazdu, elektroniczny rozdział siły hamowania znacząco poprawia bezpieczeństwo manewru.
              </CardText>
            </TopCard>
            <TopCard>
              <CardTitle>PODUSZKI <br/> POWIETRZNE</CardTitle>
              <CardLine/>
              <CardText>
                Zaawansowane, wielostopniowe poduszki powietrzne kierowcy i pasażera z przodu, dodatkowe poduszki z przodu i z tyłu oraz boczne poduszki powietrzne montowane na przednich siedzeniach są standardem.
              </CardText>
            </TopCard>
          </BlockRow>
        </div>

        <div className="container">
          <Title>ELEKTRONICZNA KONTROLA STABILNOŚCI</Title>

          <ImageAngle className={`container m0`}>
            <Image fileName={"durango_safety_03@2x.png"} alt={"Dodge Durango"}/>
          </ImageAngle>

          <BlockRow>
            <TopCard>
              <CardTitle>WYCIERACZKI <br/> Z CZUJNIKIEM DESZCZU</CardTitle>
              <CardLine/>
              <CardText>
                Wycieraczki z czujnikiem deszczu wykrywają wilgoć na przedniej szybie i włączają się automatycznie.
              </CardText>
            </TopCard>
            <TopCard>
              <CardTitle>WSPARCIE <br/> hamowania</CardTitle>
              <CardLine/>
              <CardText>
                System wspomagania hamowania w deszczu usuwa wodę z klocków hamulcowych, zbliżając niewielkim naciskiem klocek hamulcowy do tarczy. System aktywuje się gdy wycieraczki przedniej szyby są aktywne.
              </CardText>
            </TopCard>
            <TopCard>
              <CardTitle>Elektroniczna <br/> kontrola stabilności</CardTitle>
              <CardLine/>
              <CardText>
                Elektroniczna kontrola stabilności z elektronicznym ograniczaniem przechyłu pomaga zachować kontrolę nad kierunkiem jazdy na nawierzchniach o słabej przyczepności. Zapobiega unoszeniu się kół oraz możliwości wywrócenia się pojazdu.
              </CardText>
            </TopCard>
          </BlockRow>
        </div>

        <div className="container">
          {durango.map(model => (
            <Card key={model.id}>
              <CardImg>
                <Image fileName={model.image} alt={model.title}/>
              </CardImg>
              <CardContent>
                <CardTitle>{model.title}</CardTitle>
                <CardLine/>

                <CardList>
                  {model.list.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </CardList>
              </CardContent>
            </Card>
          ))}
        </div>

      </main>
    </Layout>
  )
}

export default Challenger