import styled from "styled-components";
import { breakpoints } from "../../styles/breakpoints.styles";

export const TopCard = styled.div`
  width: 32%;
  display: flex;
  flex-direction: column;
  
  ${breakpoints('tabPort') `
     width: 28rem;
     margin-bottom: 2rem;
  `}
`;

export const BlockRow = styled.div`
  display: flex;
  //align-items: center;
  justify-content: space-between;
  margin: 4rem 0;
  position: relative;
  z-index: 10;
  
  .gatsby-image-wrapper {
    margin-bottom: 2rem;
  }
  
  &.down {
    margin-bottom: -8rem;
    
    ${breakpoints('tabLand') `
       margin-bottom: -4rem;
    `}
  }
  
  ${breakpoints('tabLand') `
     justify-content: space-evenly;
  `}
  
  ${breakpoints('tabPort') `
     flex-direction: column;
     align-items: center;
  `}
`;

export const BlockRowImage = styled.div`
  width: 70%;
  margin-right: 3rem;
  
  ${breakpoints('tabPort') `
     width: 100%;
     margin-bottom: 2rem;
  `}
`;

export const BlockRowContent = styled.div`
  width: 28%;
  margin-right: 3rem;
  
  ${breakpoints('tabPort') `
     width: 100%;
     margin-bottom: 2rem;
  `}
`;

export const BlackGrid = styled.div`
  width: 100%;
  background-color: #131415;
  padding: 2px 0 4rem;
  margin-top: 4rem;
  position: relative;
  
  &.first {
    margin-top: -8rem;
    padding: 7rem 0;
  }
  
  .text {
    color: #a7a7a7;
  }
`;

export const RelativeImage = styled.div`
  position: relative;
  margin-bottom: 4rem;
`;

export const RelativeLabel = styled.div`
  position: absolute;
  z-index: 10;
  background-color: #E3E3E3;
  padding: 2rem 4rem;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  
  ${breakpoints('tabPort') `
    padding: 1rem 2rem;
    position: unset; 
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
    max-width: 100%;
  `} 
  
  .title {
    color: black;
    font-family: ${props => props.theme.fonts.heading};
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 2rem;
    
    ${breakpoints('tabPort') `
      font-size: 2rem;
      text-align: center;
    `} 
  }
  
  .text {
    color: ${props => props.theme.colors.text};
    font-size: 1.4rem;
    line-height: 2.4rem;
    
    ${breakpoints('tabPort') `
      text-align: center;
    `}
  }
  
  .align-right {
    text-align: right;
    
    ${breakpoints('tabPort') `
      text-align: center;
    `}
  }
`;

export const Card5 = styled.div`
  display: flex;
  flex-direction: column;
  width: 18%;
  
  ${breakpoints('tabLand') `
    width: 48%;
    margin-bottom: 3rem;
  `} 
  ${breakpoints('phone') `
    width: 30rem;
    margin-bottom: 3rem;
  `} 
`;